import React, {useEffect} from 'react';
import ReactMarkdown from 'react-markdown';

function Chat({ messages = [], isLoading, messagesEndRef }) {
    useEffect(() => {
        // Scroll to the bottom when messages change
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <div id="chatContainer" className="md:max-h-[75vh] px-4 w-full m-auto overflow-y-auto scroll-smooth">
            {/* Vérifiez que messages est bien un tableau */}
            {Array.isArray(messages) && messages.length > 0 ? (
                messages.map((message, index) => (
                    <div
                        key={index}
                        className={`flex space-x-3 ${
                            message.role === 'user' ? 'justify-end' : 'justify-start'
                        } my-8 ${
                            typeof message.content === 'string' && message.content.includes("//Début du pré-rapport//")
                                ? 'hidden-message'
                                : ''
                        }`}
                    >
                        {/* Affiche l'image de l'assistant si le rôle est assistant */}
                        {message.role === 'assistant' && (
                            <img
                                src="https://i.ibb.co/n6Sk79h/LOGO-1200-x-800-px.png"
                                className="h-12 w-18"
                                alt="Assistant"
                            />
                        )}
                        <div
                            className={`text-left p-5 rounded-xl text-sm md:text-base ${
                                message.role === 'user'
                                    ? 'bg-gradient-to-r from-[#DD6E79] to-[#DD6E79] rounded-tr-none shadow-[#265279] shadow-perso'
                                    : 'bg-gradient-to-r from-[#1CBDB4] to-[#1CBDB4] rounded-tl-none shadow-[#265279] shadow-perso'
                            }`}
                        >
                            {/* Vérifie si message.content est un tableau ou une chaîne */}
                            {Array.isArray(message.content) ? (
                                message.content.map((contentItem, contentIndex) => {
                                    if (contentItem.type === 'text') {
                                        return (
                                            <p key={contentIndex}>
                                                <ReactMarkdown>{contentItem.text}</ReactMarkdown>
                                            </p>
                                        );
                                    } else if (contentItem.type === 'image_url') {
                                        return (
                                            <div id="photoContainer" key={contentIndex}>
                                                <img
                                                    id="photoUser"
                                                    src={contentItem.image_url.url}
                                                    alt={`Uploaded Content ${contentIndex}`}
                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            ) : (
                                <p>
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </p>
                            )}
                        </div>
                        {/* Affiche l'image de l'utilisateur si le rôle est utilisateur */}
                        {message.role === 'user' && (
                            <img
                                src="https://i.ibb.co/b7yNknT/human.png"
                                className="h-14 w-14"
                                alt="Utilisateur"
                            />
                        )}
                    </div>
                ))
            ) : (
                console.log('aké')
            )}
            {/* Loader pour l'état de chargement */}
            {isLoading && <div className="loader"></div>}
            {/* Référence pour le scroll automatique */}
            <div ref={messagesEndRef}></div>
        </div>
    );
}

export default Chat;
