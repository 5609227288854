import React, {useState} from 'react';
import axios from 'axios';
import API_URL from './config/apiConfig';
import {useAuth} from './AuthContext';
import {useNavigate} from 'react-router-dom';

const AuthForm = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { setAuthToken } = useAuth(); // Accéder au setter du token
    const navigate = useNavigate(); // Utilisé pour rediriger après la connexion

    // Gère l'inscription ou la connexion
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const endpoint = isLogin ? `${API_URL}/auth/login` : `${API_URL}/auth/register`;
        const action = isLogin ? 'Connexion' : 'Inscription';

        try {
            const response = await axios.post(endpoint, { email, password });
            const { token, userName, userId, userEmail } = response.data;

            // Met à jour le contexte avec le token et les infos de l'utilisateur
            setAuthToken({ token, userEmail, userId, userName });

            // Redirige vers la page d'accueil ou une autre route
            navigate('/', { replace: true });
        } catch (err) {
            // Gère les erreurs
            setError(err.response?.data?.message || `Erreur lors de la ${action}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pt-12 background flex flex-col justify-center min-h-screen">
            <img
                src="https://i.ibb.co/n6Sk79h/LOGO-1200-x-800-px.png"
                className="w-80 h-auto mx-auto mt-32"
                alt="Logo"
            />
            <div className="w-7/12 bg-[#1CBDB4] rounded-lg m-auto flex flex-col items-center justify-center  h-auto py-8">
                <h2 className="text-2xl text-white font-black text-center pt-4 pb-6">Connexion</h2>
                {/* Formulaire */}
                <form className="flex flex-col w-full items-center space-y-4" onSubmit={handleSubmit}>
                    <input
                        className="rounded-lg p-2 text-lg w-3/4"
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        className="rounded-lg p-2 text-lg w-3/4"
                        type="password"
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    {error && (
                        <div className="text-red-600 text-center">
                            {error}
                        </div>
                    )}
                    <button
                        className="bg-[#DD6E79] py-3 px-8 rounded-lg text-white font-bold hover:bg-[#c65a66] transition"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Chargement...' : 'Connexion'}
                    </button>
                </form>

                {/* Changement entre inscription et connexion */}
                {/*<button*/}
                {/*    className="text-white underline hover:text-[#ffefef] transition"*/}
                {/*    onClick={() => setIsLogin(!isLogin)}*/}
                {/*    disabled={loading}*/}
                {/*>*/}
                {/*    {isLogin*/}
                {/*        ? "Première visite ? Cliquez ici pour vous inscrire"*/}
                {/*        : "Déjà inscrit ? Connectez-vous"}*/}
                {/*</button>*/}
            </div>
        </div>
    );
};

export default AuthForm;
