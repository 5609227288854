import React from 'react';

function Header({ authToken, handleLogout }) {
    return (
        <div id="nav" className="h-auto w-full z-20 flex flex-row justify-between opacity-80 bg-[#E2AE81] py-2">
            <h1 className="mt-4 ml-4 text-3xl font-extrabold text-white tracking-wider  drop-shadow-2xl">
                RESOLViA
            </h1>
            <div>
                {/*<p className="text-lg">{authToken.userId}</p>*/}
                <p className="text-lg">{authToken.userName}</p>

                {authToken?.token && (
                    <button
                        className="mr-3 mt-3 p-3 bg-[#DD6E79] rounded-xl hover:bg-[#FF0018]"
                        onClick={handleLogout}
                    >
                        Déconnexion
                    </button>
                )}
            </div>
        </div>
    );
}

export default Header;
