import {systemMessage} from "../systemMessage";
import axios from 'axios';
import {useAuth} from '../AuthContext';

const useSendMessage = (
    inputValue,
    setInputValue,
    setIsLoading,
    setSentImageUrls,
    setUploadedImageUrls,
    uploadedImageUrls,
    messages,
    setMessages
) => {
  const authInfo = useAuth();
  const userId = authInfo.authToken?.userId;
  const userName = authInfo.authToken?.userName;

  console.log(typeof messages)
  // Fonction d'analyse avec OpenAI
  // const handleAnalyze = async (input, isImage = false) => {
  //   const openai = new OpenAI({ apiKey: process.env.REACT_APP_API_KEY_OAI, dangerouslyAllowBrowser: true });
  //
  //   const instructions = 'Tu es assistant d\'un expert en bâtiment. Analyse ce document ou cette image de manière exhaustive, résume-le et extrait toutes les informations importantes.';
  //   const model = 'gpt-4o';
  //
  //   const assistant = await openai.beta.assistants.create({
  //     name: 'doc reader',
  //     instructions: instructions,
  //     model: model,
  //     tools: isImage ? [] : [{ type: 'file_search' }]
  //   });
  //
  //   let toolResources = {};
  //   if (!isImage) {
  //     toolResources.file_search = { vector_store_ids: [input] };
  //   }
  //
  //   const messageContent = isImage ? `"type": "image_file", "image_file": {"file_id": ${input.join(",")}}` : `Voici un document pour analyse : ${input}`;
  //
  //   const thread = await openai.beta.threads.create({
  //     messages: [{ role: 'user', content: messageContent }],
  //     tool_resources: toolResources
  //   });
  //
  //   const runOptions = { assistant_id: assistant.id, instructions: instructions };
  //   const run = await openai.beta.threads.runs.createAndPoll(thread.id, runOptions);
  //
  //   const messagesList = await openai.beta.threads.messages.list(thread.id);
  //
  //   if (messagesList.data.length > 0) {
  //     const lastMessage = messagesList.data[messagesList.data.length - 1];
  //     const newMessage = {
  //       role: "assistant",
  //       content: lastMessage.content[0]?.text?.value || "Aucune réponse pertinente n'a été trouvée."
  //     };
  //     setMessages(prev => [...prev, newMessage]);
  //   }
  // };

  const handleSend = async (event, text) => {
    if (event) {
      event.preventDefault();

    }
    setIsLoading(true);

    // Préparer le contenu du message incluant texte et images
    let messageContent = [];
    console.log(messageContent)
    if (text.trim()) {
      messageContent.push({ type: "text", text: text.trim() });
    }

    if (Array.isArray(uploadedImageUrls)) {
      uploadedImageUrls.forEach((url) => {
        if (url) {
          messageContent.push({
            type: "image_url",
            image_url: { url },
          });
        }
      });
    }

    const newMessage = {
      role: "user",
      content: messageContent,
    };

    if (!Array.isArray(messages)) {
      console.error('messages is not an array:', messages);
      setIsLoading(false);
      return;
    }

    // console.log(messageContent[0].text)
    const newMessages = [...messages, newMessage];
    console.log(newMessages)

    setMessages(newMessages);
    setUploadedImageUrls([]);
    setInputValue('');

    // Enregistrer le message utilisateur dans la base de données
    try {

      await axios.post('http://localhost:3001/messages', {
        user: 'User', // Remplace par la logique d'authentification de ton application
        content: messageContent[0].text,
        role: 'user',
        userId: userId

      });


    } catch (error) {

      console.error('Erreur lors de l\'enregistrement des messages', error);

    }

    try {

      await axios.post('http://localhost:3001/images', {
        userId: userId,
        imagePath: messageContent[0].image_url.url

      });

    } catch (error) {

      console.error('Erreur lors de l\'enregistrement des images', error);
      await axios.post('http://localhost:3001/log-client-error', {
        error: (userName + " " + error.toString()),
        location: 'handleSend'
      });
    }

    try {

      const payload = {
        model: "gpt-4o",
        messages: [systemMessage, ...newMessages],
        max_tokens: 2500,
        temperature: 0.7,
      }


      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY_OAI}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json();
        // throw new Error(`status: ${response.status}, Message : ${errorData.error.message}`);
        await axios.post('http://localhost:3001/log-client-error', {
          error: (userName + " " + errorData.toString()),
          location: 'handleSend'
        });
      }

      const data = await response.json();
      const assistantMessage = { role: "assistant", content: data.choices[0].message.content };

      // Ajouter la réponse de l'assistant aux messages
      setMessages(prev => [...prev, assistantMessage]);

      // Enregistrer le message assistant dans la base de données
      try {
        await axios.post('http://localhost:3001/messages', {
          user: 'Assistant', // Identifiant pour l'assistant
          content: assistantMessage.content,
          role: 'assistant',
          userId: userId
        });
        // console.log(assistantMessage.content);
      } catch (error) {
        await axios.post('http://localhost:3001/log-client-error', {
          error: (userName + " " + error.toString()),
          location: 'handleSend'
        });
      }

    } catch (error) {

      await axios.post('http://localhost:3001/log-client-error', {
        error: (userName + " " + error.toString()),
        location: 'handleSend'
      });
    } finally {
      setIsLoading(false);
    }

    setSentImageUrls(prev => [...prev, ...uploadedImageUrls]);
  };

  return { handleSend };
};

export default useSendMessage;