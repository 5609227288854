import {useEffect, useState} from 'react';
import {useAuth} from '../AuthContext';
import axios from "axios";


const useImageUpload = (maxSizeMB = 5, onError = () => {} ) => {
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const [largeImageUrls, setLargeImageUrls] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);


  const authInfo = useAuth();
  const userId = authInfo.authToken.userId;
  const userName = authInfo.authToken.userName;

  const resetModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  }

  const handleImageChange = async (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const maxSize = maxSizeMB * 1024 * 1024; // Convertir MB en octets
      const newLargeImageUrls = [];

      for (const file of files) {
        if (file.size > maxSize) {
          newLargeImageUrls.push(URL.createObjectURL(file));
        }
      }

      setLargeImageUrls(prevUrls => [...prevUrls, ...newLargeImageUrls]);

      const validFiles = files.filter(file => file.size <= maxSize);

      // Initialisation de la variable urls avant de la traiter
      const urls = await Promise.all(validFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await axios.post('http://localhost:3001/documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          });
          const url = response.data.url;
          console.log("Fichier téléchargé", response.data);
          console.log("url " + url);
          return url;

        } catch (error) {
          await axios.post('http://localhost:3001/log-client-error', {
            error: (userName + " " + error.toString()),
            location: 'handleSend'
          });
          // Retourne null si une erreur se produit
          return null;
        }
      }));

      // Utiliser urls ici après l'avoir initialisée
      setUploadedImageUrls(prevUrls => [...prevUrls, ...urls.filter(url => url)]);
    }
  };

  const removeImage = (urlToRemove) => {
    setUploadedImageUrls(prevUrls => prevUrls.filter(url => url !== urlToRemove));
    setLargeImageUrls(prevUrls => prevUrls.filter(url => url !== urlToRemove));
  };

  useEffect(() => {
    if (uploadedImageUrls.length > 0) {
      setErrorMessage(''); // Clear error message when images are successfully uploaded
    }
  }, [uploadedImageUrls]);

  useEffect(() => {
    if (!isModalOpen) {
      resetModal(); // Reset modal state when it is closed
    }
  }, [isModalOpen]);

  return {
    uploadedImageUrls,
    largeImageUrls,
    setUploadedImageUrls,
    handleImageChange,
    errorMessage,
    isModalOpen,
    setIsModalOpen,
    setErrorMessage,
    resetModal,
    removeImage
  };
};

export default useImageUpload;
