import {Route, Routes} from 'react-router-dom';
import ResetPassword from './ResetPassword';
import Chat from "./Chat";
import AuthForm from "./AuthForm";
import PrivateRoute from "./PrivateRoute";
import Test from "./Test";

const AppRoutes = () => (
    // <Router>
        <Routes>
            <Route path="/reset-password/:token" element={<ResetPassword/>} />
            {/* Route protégée */}
            <Route path="/" element={<PrivateRoute element={Chat} />} />
            {/* Route pour la connexion */}
            <Route path="/login" element={<AuthForm />} />
            <Route path="/test" element={<Test/>} />
        </Routes>
    // </Router>


);

export default AppRoutes;
